/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/no-extraneous-dependencies */
import { sharedRef } from '@vue-storefront/core';
import { useUser as useUserComposable } from '@gemini-vsf/composables';
import { useGeminiApi, useApi, useUiNotification, useRecaptcha, useUiState } from '~/composables';
import { useContext } from '@nuxtjs/composition-api';
import { generateUserData } from './_helpers';

const CREATE_CUSTOMER = `
  mutation createCustomer($input: CustomerCreateInput!) {
    createCustomerV2(input: $input) {
      customer {
        uid
      }
    }
  }
`;

const useUser = () => {
  const {
    app,
    app: { $cookies },
  } = useContext();
  const {
    user,
    load: loadUser,
    logout: logoutComposable,
    updateUser: updateUserComposable,
    login: loginComposable,
    changePassword: changePasswordComposable,
    loading,
    error,
    register: registerComposable,
  } = useUserComposable();
  const { loginAndRegisterModalOpen, toggleLoginAndRegisterModal } = useUiState();
  const { isEnabled: isRecaptchaEnabled, $recaptcha: recaptcha } = useRecaptcha();
  const { send: sendNotification } = useUiNotification();
  const { query } = useApi();
  const { validateRecaptcha } = useGeminiApi();
  const customerPermissions = sharedRef(null, 'user-permissions');
  const isAuthenticated = sharedRef(null, 'user-authenticated');
  const registering = sharedRef(false, 'user-registering');
  const setAuthenticatedState = () => {
    isAuthenticated.value = !!$cookies.get('vsf-customer');
  };

  const load = async () => {
    setAuthenticatedState();
    await loadUser({ customQuery: { customer: 'customerCustom' } });
  };

  const logout = async () => {
    await logoutComposable();
    setAuthenticatedState();
  };

  const updateUser = async (params: any) => {
    setAuthenticatedState();
    await updateUserComposable(params);
  };

  const login = async (params: any) => {
    await loginComposable({ ...params, customQuery: { customer: 'customerCustom' } });
    setAuthenticatedState();
  };

  const registerNotification = (isError = true) => {
    sendNotification({
      id: isError ? Symbol('create_customer_error') : Symbol('create_customer_success'),
      message: isError
        ? `${app.i18n.t('An error occurred while creating your account. Please retry or contact customer support.')}`
        : `${app.i18n.t('Account created successfully!')}`,
      type: isError ? 'danger' : 'success',
      icon: isError ? 'danger' : 'success',
      persist: isError,
      title: isError ? 'Error' : 'Success',
    });
  };

  const register = async (userData: any) => {
    registering.value = true;
    try {
      if (isRecaptchaEnabled.value) {
        const recaptchaToken = await recaptcha.execute('register');
        const recaptchaSuccess = await validateRecaptcha(recaptchaToken);
        if (!recaptchaSuccess) {
          registering.value = false;
          registerNotification();
          return;
        }
      }
      const { email, password, ...baseData } = generateUserData(userData);
      const customerCreateResponse = await query(CREATE_CUSTOMER, {
        input: { email, password, ...baseData },
      });
      if (!customerCreateResponse || customerCreateResponse.errors) {
        registering.value = false;
        registerNotification();
        return;
      }
      await login({ user: { username: email, password, is_subscribed: userData?.is_subscribed || false } });
      registering.value = false;
      if (loginAndRegisterModalOpen.value) toggleLoginAndRegisterModal();
      registerNotification(false);
    } catch (registerJSError) {
      console.error('register ~ error:', registerJSError);
      registerNotification();
    }
    registering.value = false;
  };

  const changePassword = async (params: any) => {
    await changePasswordComposable(params);
  };

  const registerAndLogin = async (params: any) => {
    await registerComposable({ ...params, customQuery: { customer: 'customerCustom' } });
    setAuthenticatedState();
  };

  return {
    load,
    logout,
    updateUser,
    register,
    login,
    changePassword,
    user,
    isAuthenticated,
    loading,
    error,
    customerPermissions,
    setAuthenticatedState,
    registering,
    registerAndLogin,
  };
};

export default useUser;
